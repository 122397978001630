import React from "react"
import { IoIosHelpCircleOutline } from "react-icons/io"

import "../assets/components/bootstrap/css/bootstrap.min.css"

import "../assets/scss/theme.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import Page1 from "../../static/images/1.svg"
// import Page2 from "../../static/images/2.svg"
// import Page3 from "../../static/images/3.svg"
import Arrows from "../../static/images/arrows1.svg"

const CustomHeader = ({ header }) => (
  <div className="row mb-6 curvedshadow curvedshadow-after curvedshadow-before bg-white">
    <div className="col-12">
      <header className="text-center">
        <h1 className="my-2">{header}</h1>
      </header>
    </div>
  </div>
)

const App = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <header className="top-of-page" id="top">
        <div className="container pt-6">
          <div className="row d-flex align-items-center text-center text-lg-left">
            <div className="col-12 col-lg-6 py-6">
              <h1 className="fw-bold mb-4">
                Payment Transaction Firewall
                <br /> and bad-actor detection
              </h1>
            </div>
            <div className="col-12 col-lg-6 text-center">
              {/* <Page1 /> */}
              <img src="images/1.svg" alt="" />
            </div>
          </div>
        </div>
      </header>

      <main className="main-container">
        <section id="antifraud" className="section">
          <div className="container">
            <CustomHeader header="Fraud Detection" />

            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 text-center">
                {/* <Page2 /> */}
                <img src="images/2.svg" alt="" />
              </div>
              <div className="col-12 col-md-2 d-none d-md-block text-right pr-5">
                <IoIosHelpCircleOutline
                  className="fs-5"
                  style={{ color: "#9BBB5C" }}
                />
              </div>
              <div className="col-12 col-md-10">
                <h3>Clairvoyance</h3>
                <p>
                  That's what we call the ability to detect and prevent
                  charge-backs before they are processed.{" "}
                  <br className="d-none d-sm-block" />
                  Basically, eliminate fraud before it happens.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="section pt-0">
          <div className="container">
            <CustomHeader header="Features" />

            <div className="row d-flex align-items-center">
              <div className="col-12 col-md-7 px-4 p-md-0">
                <div className="row">
                  <div className="col-12 addon-before color-1">
                    <h4>Sophisticated BIN rules</h4>
                    <p className="m-0">
                      Route, manage and organize by BIN to match customers to
                      the most appropriate processor.
                    </p>
                  </div>
                  <div className="col-12 addon-before color-2 mt-2">
                    <h4>Real-time transaction scoring</h4>
                    <p className="m-0">
                      Apply scoring rules to grade transactions and categorize
                      risk.
                    </p>
                  </div>
                  <div className="col-12 addon-before color-3 mt-2">
                    <h4>Know-Your-Customer</h4>
                    <p className="m-0">
                      Administer KYC documents for payouts and risky customers.
                      Provide an anti-money laundering audit trail.
                    </p>
                  </div>
                  <div className="col-12 addon-before color-4 mt-2">
                    <h4>Route by Limits</h4>
                    <p className="m-0">
                      Apply volume limits to transactions globally by processor,
                      customer or card; per day, week or month.
                    </p>
                  </div>
                  <div className="col-12 addon-before color-5 mt-2">
                    <h4>Velocity Throttling</h4>
                    <p className="m-0">
                      Limit and route by number of attempts/approvals by
                      processor, customer or card; per day, week or month.
                    </p>
                  </div>
                  <div className="col-12 addon-before color-5 mt-2">
                    <h4>Processor Monitoring and Failover</h4>
                    <p className="m-0">
                      Detect processor downtime and re-route both actively and
                      passively.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-none d-md-block">
                <Arrows />
              </div>
            </div>
          </div>
        </section>
        <section id="payments" className="section py-0">
          <div className="container">
            <CustomHeader header="Payments Platform" />

            <div className="row">
              <div className="col-12 text-center">
                {/* <Page3 /> */}
                <img src="images/3.svg" alt="" />
              </div>
            </div>
          </div>
        </section>
      </main>

      <div
        className="container-fluid curvedshadow curvedshadow-before bg-white mt-6"
        style={{ height: 40 }}
      ></div>
    </Layout>
  )
}

export default App
